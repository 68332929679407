<template></template>
<script>
import { request } from '@/api/service'
export default {
  name: 'PayPage',
  async created() {
    debugger
    const { orderNo } = this.$route.query
    if (!orderNo) return
    try {
      const res = await request({
        url: `/afis-auto-web/auto/pay/payment/${orderNo}`,
        method: 'post'
      })
      if (!res || !res.payUrl) {
        Toast('获取支付链接失败，请稍后重试')
        return
      }
      window.location.href = res.payUrl
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style>

</style>
